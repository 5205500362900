import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Register from "./components/Register"
import TermsOfService from "./components/TermsOfService";
import Login from "./components/Login";
import MainComponent from "./components/MainComponent";
import Home from "./components/Home"; // Import the new Home component
import PrivateRoute from "./components/PrivateRoute";
import PublicRoute from "./components/PublicRoute";
import "./App.css";

function App() {
    return (
        <div className="App">
            <Router>
                <Routes>
                    <Route
                        path="/register"
                        element={
                            <PublicRoute>
                                <Register />
                            </PublicRoute>
                        }
                    />
                    <Route
                        path="/login"
                        element={
                            <PublicRoute>
                                <Login />
                            </PublicRoute>
                        }
                    />
                    <Route
                        path="/terms-of-service"
                        element={
                            <PublicRoute>
                                <TermsOfService />
                            </PublicRoute>
                        }
                    />
                    <Route
                        path="/wallet"
                        element={
                            <PrivateRoute>
                                <MainComponent />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/"
                        element={
                                <Home />
                        }
                    />
                </Routes>
            </Router>
        </div>
    );
}

export default App;
