import React from "react";
import { Typography, Link, Box } from "@mui/material";
import TokenBanner from "./TokenBanner";

const LoggedOutHome = () => {
    return (
        <>
            <TokenBanner />
            <Typography
                variant="h4"
                gutterBottom
                sx={{
                    color: "#0A5C36",
                    textAlign: "left", // Align text to the left
                }}
            >
                Welcome to Token Summary
            </Typography>
            <Box
                sx={{
                    display: "flex", // Flex layout
                    flexDirection: "row", // Side by side layout
                    justifyContent: "space-between", // Space between items
                    alignItems: "center", // Vertically align both sides
                    padding: "20px",
                    maxWidth: "1200px", // Set a max width for the container
                    margin: "0 auto", // Center the content
                    minHeight: "400px", // Optional: Set a minimum height for better vertical alignment
                }}
            >
                {/* Left Section (Header Text) */}
                <Box
                    sx={{
                        flex: "1", // Takes up one portion of the space
                        marginRight: "20px", // Adds space between text and image
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center", // Center content vertically
                    }}
                >
                    <Typography
                        variant="h6"
                        gutterBottom
                        sx={{
                            color: "#0A5C36",
                            maxWidth: "700px", // Ensure body text width is limited
                            textAlign: "left", // Align text to the left
                        }}
                    >
                        The purpose of this app is to help Solana traders
                        identify and analyze a token's holders. For a given
                        token, we generate a report on the holders' wallets,
                        including their funding wallet and age, which is a common factor
                        for bundles.
                    </Typography>
                </Box>

                {/* Right Section (Image and Caption) */}
                <Box
                    sx={{
                        flex: "1", // Takes up one portion of the space
                        display: "flex",
                        flexDirection: "column", // Stack image and caption
                        alignItems: "center", // Center align items in column
                    }}
                >
                    <Box
                        component="img"
                        src={require("../assets/example.png")}
                        alt="Example Report"
                        sx={{
                            maxWidth: "800px", // Set a max width for the image
                            width: "100%", // Make it responsive, scaling within the max width
                            height: "auto", // Maintain aspect ratio
                            marginBottom: "10px", // Space between image and caption
                        }}
                    />
                    <Typography
                        variant="caption"
                        sx={{
                            maxWidth: "800px", // Match the image's width
                            color: "#555",
                            textAlign: "center", // Center-align the caption text
                        }}
                    >
                        Above you will see 8 of the top 10 holders were all
                        funded from the same wallet, and all created on the same
                        page. This is information that is not easily accessible
                        when trading coins.
                    </Typography>
                </Box>
            </Box>

            <Typography
                variant="h6"
                gutterBottom
                sx={{
                    maxWidth: "700px", // Keep consistent width
                    margin: "20px auto",
                    textAlign: "center",
                }}
            >
                Please{" "}
                <Link
                    href="/register"
                    sx={{ color: "#0A5C36", textDecoration: "none" }}
                >
                    Register
                </Link>{" "}
                or{" "}
                <Link
                    href="/login"
                    sx={{ color: "#0A5C36", textDecoration: "none" }}
                >
                    Login
                </Link>{" "}
                to continue.
            </Typography>
        </>
    );
};

export default LoggedOutHome;
