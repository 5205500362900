import React, { useState } from "react";
import {
    Button,
    Modal,
    Typography,
    Box,
    CircularProgress,
    Tooltip, // Import Tooltip
} from "@mui/material";
import PropTypes from "prop-types";

const PurchaseSubscription = ({
    subscriptionModalOpen,
    setSubscriptionModalOpen,
    balance, // User's balance passed as a prop
}) => {
    const [selectedOption, setSelectedOption] = useState(null);
    const [loading, setLoading] = useState(false);
    const [confirmationMessage, setConfirmationMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const url = process.env.REACT_APP_API_BASE_URL;

    const closeSubscriptionModal = () => setSubscriptionModalOpen(false);

    const handleSubscriptionSelect = async (tier) => {
        setSelectedOption(tier);
        setLoading(true);
        setConfirmationMessage("");
        setErrorMessage("");

        try {
            const token = localStorage.getItem("token");
            const response = await fetch(
                `${url}/api/user/purchase-subscription`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify({ tier }),
                }
            );

            const data = await response.json();

            if (response.ok) {
                setConfirmationMessage(`Subscription for ${tier} confirmed!`);
            } else {
                setErrorMessage(
                    data.error || "Failed to purchase subscription."
                );
            }
        } catch (error) {
            setErrorMessage("Failed to purchase subscription.");
        } finally {
            setLoading(false);
        }
    };

    // Define the required SOL amounts for each subscription tier
    const requiredBalance = {
        "1-day": 0.1,
        "1-week": 0.45,
        "1-month": 1.0,
    };

    return (
        <div>
            <Modal
                open={subscriptionModalOpen}
                onClose={closeSubscriptionModal}
                aria-labelledby="subscription-modal-title"
            >
                <Box
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: 400,
                        bgcolor: "background.paper",
                        boxShadow: 24,
                        p: 4,
                        borderRadius: 2,
                        textAlign: "center",
                        outline: "none",
                    }}
                >
                    <Typography
                        id="subscription-modal-title"
                        variant="h6"
                        component="h2"
                        gutterBottom
                        sx={{ fontWeight: 600 }}
                    >
                        Choose a Subscription
                    </Typography>
                    <Typography
                        variant="body2"
                        color="textSecondary"
                        gutterBottom
                    >
                        Select a subscription plan to proceed. Once you purchase a subscription, you will be able to generate unlimited reports.
                    
                    </Typography>

                    {/* Subscription Buttons */}
                    {["1-day", "1-week", "1-month"].map((tier) => (
                        <Tooltip
                            key={tier}
                            title={
                                balance >= requiredBalance[tier]
                                    ? ""
                                    : "Transfer more Solana to purchase this subscription"
                            }
                        >
                            <span>
                                <Button
                                    onClick={() =>
                                        handleSubscriptionSelect(tier)
                                    }
                                    sx={{ mt: 2, mb: 1 }}
                                    variant="outlined"
                                    fullWidth
                                    disabled={balance < requiredBalance[tier]} // Disable if balance is insufficient
                                >
                                    {`${tier.replace("-", " ")} - ${
                                        requiredBalance[tier]
                                    } SOL`}
                                </Button>
                            </span>
                        </Tooltip>
                    ))}

                    {/* Loading Spinner */}
                    {loading && (
                        <CircularProgress
                            size={24}
                            sx={{ mt: 2, color: "primary.main" }}
                        />
                    )}

                    {/* Confirmation Message */}
                    {confirmationMessage && (
                        <Typography sx={{ mt: 2, color: "green" }}>
                            {confirmationMessage}
                        </Typography>
                    )}

                    {/* Error Message */}
                    {errorMessage && (
                        <Typography sx={{ mt: 2, color: "red" }}>
                            {errorMessage}
                        </Typography>
                    )}

                    <Button
                        onClick={closeSubscriptionModal}
                        variant="contained"
                        sx={{ mt: 3 }}
                    >
                        Close
                    </Button>
                </Box>
            </Modal>
        </div>
    );
};

export default PurchaseSubscription;
