import React, { useState } from "react";
import axios from "axios";
import {
    TextField,
    Button,
    Container,
    Typography,
    Box,
    Link,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import TokenBanner from "./TokenBanner";

const Login = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const navigate = useNavigate();
    const url = process.env.REACT_APP_API_BASE_URL;

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(
                `${url}/api/auth/login`,
                { email, password }
            );
            localStorage.setItem("token", response.data.token);
            setSuccess("Login successful.");
            setEmail("");
            setPassword("");
            setError("");
            navigate("/");
        } catch (error) {
            setError("Username or password incorrect");
        }
    };

    return (
        <Container
            maxWidth="sm"
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "100vh",
                backgroundColor: "#ffffff",
            }}
        >
            <TokenBanner />
            <Box
                sx={{
                    width: "100%",
                    maxWidth: 400,
                    p: 3,
                    borderRadius: 2,
                    boxShadow: 3,
                    backgroundColor: "#ffffff",
                    textAlign: "center",
                }}
            >
                <Typography variant="h4" gutterBottom sx={{ color: "#0A5C36" }}>
                    Login
                </Typography>
                <form onSubmit={handleSubmit}>
                    <TextField
                        label="Username"
                        type="text"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                    <TextField
                        label="Password"
                        type="password"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                    {error && <Typography color="error">{error}</Typography>}
                    {success && (
                        <Typography color="success">{success}</Typography>
                    )}
                    <Button
                        type="submit"
                        variant="contained"
                        fullWidth
                        sx={{
                            mt: 2,
                            bgcolor: "#0A5C36",
                            "&:hover": {
                                bgcolor: "#1D6A4B", // Lighter green for hover
                            },
                        }}
                    >
                        Login
                    </Button>
                </form>
                <Typography sx={{ mt: 2 }}>
                    Don't have an account?{" "}
                    <Link href="/register" color="#0A5C36">
                        Register
                    </Link>
                </Typography>
            </Box>
        </Container>
    );
};

export default Login;
