import React, { useState, useEffect } from "react";
import {
    Box,
    Typography,
    Avatar,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Grid,
    Divider,
    Link,
    Button,
    Tooltip,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

// Helper function to generate a unique color for each address
const getColorForAddress = (index) => {
    const colors = [
        "#ffcccc",
        "#ccccff",
        "#ffffcc",
        "#ccffff",
        "#ffccff",
        "#ff9999",
        "#99ff99",
    ];
    return colors[index % colors.length];
};

const getColorForAddressGroup = (index) => {
    const colors = ["#AFE1AF", "#ffff99", "#99ffff", "#ff99ff"];
    return colors[index % colors.length];
};

// Helper function to format large numbers
const formatNumber = (num) => {
    if (num >= 1e12) {
        return (num / 1e12).toFixed(2) + "m";
    } else if (num >= 1e9) {
        return (num / 1e9).toFixed(2) + "k";
    } else {
        return num.toString();
    }
};

const addressMapping = {
    FWznbcNXWQuHTawe9RxvQ2LdCENssh12dsznf4RiouN5: "Kraken",
    "2AQdpHJ2JpcEgPiATUXjQxA8QmafFegfQwSLWSprPicm": "Coinbase",
    GJRs4FwHtemZ5ZE9x3FNvJ8TMwitKTh21yxdRPqn7npE: "Coinbase",
    H8sMJSCQxfKiFTCfDR3DUMLPwcRbM61LGFJ8N4dK3WjS: "Coinbase",
    "5tzFkiKscXHK5ZXCGbXZxdw7gTjjD1mBwuoFbhUvuAi9": "Binance",
    "5VCwKtCXgCJ6kit5FybXjvriW3xELsFDhYrPSqtJNmcD": "OKX",
    AobVSwdW9BbpMdJvTqeCN4hPAmh4rHm7vwLnQ5ATSyrS: "crypto.com",
    AC5RDfQFmDS1deWZos921JfqscXdByf8BKHs5ACWjtW2: "bybit",
    BmFdpraQhkiDQE6SnfG5omcA1VwzqfXrwtNYBwWTymy6: "Kucoin",
    ASTyfSima4LLAdDgoFGkgqoKowG1LZFDr9fAQrg7iaJZ: "Mexc",
    A77HErqtfN1hLLpvZ9pCtu66FEtM8BveoaKbbMoZ4RiR: "Bitget",
};

const getFundedFromSource = (address) => {
    // Check if the address is in the mapping
    if (address in addressMapping) {
        return addressMapping[address];
    }

    // If address is not found in the mapping, return a truncated version of the address
    return `${address.slice(0, 4)}...${address.slice(-4)}`;
};

// Helper function to identify duplicate walletFundedFrom addresses and map them to colors
const getFundedFromColorsAndTotals = (holders) => {
    const fundedFromCount = {};
    const fundedFromColors = {};
    const fundedFromTotals = {};
    let colorIndex = 0;

    holders.forEach((holder) => {
        if (holder.walletFundedFrom in addressMapping) {
            return; // Do not highlight the row
        }
        const { walletFundedFrom, percentHolding } = holder;

        if (!fundedFromCount[walletFundedFrom]) {
            fundedFromCount[walletFundedFrom] = 0;
            fundedFromTotals[walletFundedFrom] = 0;
        }

        fundedFromCount[walletFundedFrom]++;
        fundedFromTotals[walletFundedFrom] += percentHolding;

        if (fundedFromCount[walletFundedFrom] > 1) {
            if (!fundedFromColors[walletFundedFrom]) {
                fundedFromColors[walletFundedFrom] =
                    getColorForAddress(colorIndex);
                colorIndex++;
            }
        }
    });

    return { fundedFromColors, fundedFromTotals };
};

const identifyLinkedWalletGroups = (holders) => {
    const walletToGroup = {}; // Ensure this remains an object
    const groups = [];

    holders.forEach((holder) => {
        const walletAddress = holder.walletAddress;
        const walletFundedFrom = holder.walletFundedFrom;

        if (walletFundedFrom in addressMapping) {
            return; // Do not highlight the row for known addresses like Kraken, Coinbase, etc.
        }

        // Only proceed if walletAddress matches any walletFundedFrom
        let groupFound = null;
        groups.forEach((group, index) => {
            if (group.has(walletAddress) || group.has(walletFundedFrom)) {
                groupFound = index;
            }
        });

        if (groupFound !== null) {
            // Add both walletAddress and walletFundedFrom to the existing group
            groups[groupFound].add(walletAddress);
            groups[groupFound].add(walletFundedFrom);
        } else if (holders.some((h) => h.walletAddress === walletFundedFrom)) {
            // Create a new group if walletFundedFrom matches any walletAddress
            const newGroup = new Set([walletAddress, walletFundedFrom]);
            groups.push(newGroup);
        }
    });

    // Map wallets to their group index
    groups.forEach((group, index) => {
        group.forEach((wallet) => {
            walletToGroup[wallet] = index; // Correctly assign the group index to the wallet address
        });
    });

    console.log(walletToGroup);

    return walletToGroup; // Ensure this is an object mapping wallet addresses to group indices
};

const Report = ({ coin, token, balance, isSubscriptionActive }) => {
    // Identify colors for each unique walletFundedFrom address
    const { fundedFromColors, fundedFromTotals } = getFundedFromColorsAndTotals(
        coin.report.topHolders
    );
    const url = process.env.REACT_APP_API_BASE_URL;
    const [buttonStates, setButtonStates] = useState({});
    const coinId = coin.coinId._id;
    const buttonKey = coin.coinAddress;
    const walletToGroup = identifyLinkedWalletGroups(coin.report.topHolders);
    const [freshWalletsCount, setFreshWalletsCount] = useState(0); // State for counting fresh wallets

    // Function to start generating a new report
    const generateNewReport = async () => {
        const currentTime = Date.now();
        const cooldownDuration = 180; // Cooldown in seconds

        // Set button state as generating
        setButtonStates((prevState) => ({
            ...prevState,
            [buttonKey]: {
                isGenerating: true,
                cooldown: prevState[buttonKey]?.cooldown || cooldownDuration,
            },
        }));
        localStorage.setItem(`reportIsGenerating_${buttonKey}`, "true");
        localStorage.setItem(
            `reportCooldownStart_${buttonKey}`,
            currentTime.toString()
        );
        localStorage.setItem(
            `reportCooldown_${buttonKey}`,
            cooldownDuration.toString()
        );

        try {
            const response = await fetch(`${url}/api/user/generate-report`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({ coinId }),
            });

            const data = await response.json();

            if (response.status === 429) {
                const cooldownTime = parseInt(data.error.match(/\d+/)[0], 10);
                setButtonStates((prevState) => ({
                    ...prevState,
                    [buttonKey]: {
                        isGenerating: false,
                        cooldown: cooldownTime,
                    },
                }));
                localStorage.setItem(
                    `reportCooldown_${buttonKey}`,
                    cooldownTime.toString()
                );
            } else if (response.ok) {
                setButtonStates((prevState) => ({
                    ...prevState,
                    [buttonKey]: {
                        isGenerating: false,
                        cooldown: cooldownDuration,
                    },
                }));
                localStorage.setItem(
                    `reportCooldown_${buttonKey}`,
                    cooldownDuration.toString()
                );
            }
        } catch (error) {
            console.error("Failed to generate report:", error);
        }
    };

    useEffect(() => {
        // Reset count for fresh wallets on component mount
        let count = 0;

        // Count fresh wallets based on the walletAge condition
        coin.report.topHolders.forEach((holder) => {
            if (
                new Date(holder.walletAge).toLocaleDateString() ===
                new Date().toLocaleDateString()
            ) {
                count += 1; // Increment count if the wallet was created today
            }
        });

        setFreshWalletsCount(count); // Update the state with the fresh wallets count
    }, [coin]); // Re-run effect when `coin` changes

    useEffect(() => {
        const currentTime = Date.now();
        const savedCooldown = parseInt(
            localStorage.getItem(`reportCooldown_${buttonKey}`),
            10
        );
        const cooldownStart = parseInt(
            localStorage.getItem(`reportCooldownStart_${buttonKey}`),
            10
        );
        const isGenerating =
            localStorage.getItem(`reportIsGenerating_${buttonKey}`) === "true";

        if (!isNaN(savedCooldown) && !isNaN(cooldownStart)) {
            const elapsedTime = Math.floor(
                (currentTime - cooldownStart) / 1000
            );
            const remainingCooldown = Math.max(savedCooldown - elapsedTime, 0);

            setButtonStates((prevState) => ({
                ...prevState,
                [buttonKey]: {
                    isGenerating,
                    cooldown: remainingCooldown,
                },
            }));

            if (remainingCooldown <= 0) {
                localStorage.removeItem(`reportCooldown_${buttonKey}`);
                localStorage.removeItem(`reportCooldownStart_${buttonKey}`);
                localStorage.removeItem(`reportIsGenerating_${buttonKey}`);
            }
        } else {
            setButtonStates((prevState) => ({
                ...prevState,
                [buttonKey]: {
                    isGenerating,
                    cooldown: 0,
                },
            }));
        }

        const interval = setInterval(() => {
            setButtonStates((prevState) => {
                const buttonState = prevState[buttonKey];
                if (!buttonState) return prevState;
                const { cooldown } = buttonState;
                if (cooldown <= 1) {
                    clearInterval(interval);
                    localStorage.removeItem(`reportCooldown_${buttonKey}`);
                    localStorage.removeItem(`reportCooldownStart_${buttonKey}`);
                    localStorage.removeItem(`reportIsGenerating_${buttonKey}`);
                    return {
                        ...prevState,
                        [buttonKey]: { isGenerating: false, cooldown: 0 },
                    };
                }
                return {
                    ...prevState,
                    [buttonKey]: { ...buttonState, cooldown: cooldown - 1 },
                };
            });
        }, 1000);

        return () => clearInterval(interval); // Cleanup on component unmount
    }, [buttonKey]);
    const renderedGroups = new Set();

    const buttonState = buttonStates[buttonKey] || {
        isGenerating: false,
        cooldown: 0,
    };

    return (
        <Box
            sx={{
                width: "100%",
                padding: 2,
                borderRadius: 1,
                backgroundColor: "#f9f9f9",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
            }}
        >
            {/* Coin Image and Details */}
            <Box
                sx={{
                    width: "100%",
                    padding: 2,
                    borderRadius: 1,
                    backgroundColor: "#ffffff",
                    display: "flex",
                    alignItems: "center",
                    gap: 2, // Adds space between the image and the details
                    boxShadow: 1, // Adds a subtle shadow to the card
                }}
            >
                {/* Coin Image */}
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center", // Center both the avatar and link
                    }}
                >
                    {coin.coinImage && (
                        <Avatar
                            src={coin.coinImage}
                            alt={coin.coinName}
                            sx={{
                                width: 150,
                                height: 150,
                                borderRadius: 0,
                                mb: 0, // Ensure no extra space below avatar
                            }}
                        />
                    )}

                    <Typography
                        variant="body2"
                        align="center"
                        sx={{ mt: 0 }} // No extra margin on top to keep the link right under the avatar
                    >
                        Telegram -{" "}
                        <Link
                            href="https://t.me/tokensummary"
                            target="_blank"
                            rel="noopener"
                            sx={{
                                textDecoration: "none",
                                color: "#0A5C36",
                                "&:hover": {
                                    textDecoration: "underline",
                                },
                            }}
                        >
                            <Typography
                                component="span"
                                sx={{ fontWeight: "bold" }}
                            >
                                @tokensummary
                            </Typography>
                        </Link>
                    </Typography>
                    <Link
                        href="https://tokensummary.xyz"
                        target="_blank"
                        rel="noopener"
                        sx={{
                            textDecoration: "none",
                            color: "#0A5C36",
                            "&:hover": {
                                textDecoration: "underline",
                            },
                        }}
                    >
                        <Typography
                            component="span"
                            sx={{ fontWeight: "bold" }}
                        >
                            tokensummary.xyz
                        </Typography>
                    </Link>
                </Box>

                {/* Details */}
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        flex: 1,
                        position: "relative",
                    }}
                >
                    {/* Report Header */}
                    <Typography variant="h6" sx={{ mb: 1 }}>
                        Report Generated At:{" "}
                        {new Date(coin.report.timeGenerated).toLocaleString()}
                    </Typography>

                    {/* Coin Name */}
                    <Typography variant="h5" sx={{ mb: 1 }}>
                        {coin.coinName}
                    </Typography>

                    {/* Coin Address */}
                    <Typography variant="body1" sx={{ mb: 1 }}>
                        <strong>Address:</strong>{" "}
                        <a
                            href={`https://solscan.io/account/${coin.coinAddress}`}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {coin.coinAddress}
                        </a>
                    </Typography>

                    {/* Coin Ticker */}
                    <Typography variant="body1" sx={{ mb: 1 }}>
                        <strong>Ticker:</strong> {coin.coinTicker}
                    </Typography>

                    {/* Coin Holders */}
                    <Typography variant="body1" sx={{ mb: 1 }}>
                        <strong>Holders:</strong> {coin.report.holders}
                    </Typography>

                    {/* Coin Supply */}
                    <Typography variant="body1">
                        <strong>Supply:</strong> {coin.report.supply}
                    </Typography>
                    {/* Generate Report Button in Bottom Right */}
                    <Box
                        sx={{
                            position: "absolute",
                            bottom: 16,
                            right: 16,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-end",
                            zIndex: "100",
                        }}
                    >
                        <Tooltip
                            title={
                                !isSubscriptionActive
                                    ? "Please activate subscription to add token"
                                    : ""
                            }
                            arrow
                            sx={{
                                backgroundColor: "#f5f5f9",
                                color: "#555",
                                fontSize: "1rem",
                                borderRadius: "4px",
                                padding: "8px",
                            }}
                        >
                            <span
                                style={{
                                    display: "inline-block", // Ensure span can receive hover
                                    position: "relative",
                                    width: "auto", // Adjust width dynamically
                                    height: "auto", // Adjust height dynamically
                                }}
                            >
                                <Button
                                    onClick={generateNewReport}
                                    variant="contained"
                                    sx={{
                                        position: "relative", // Center the button
                                        padding: "12px 24px",
                                        backgroundColor: isSubscriptionActive
                                            ? "#1976d2"
                                            : "#ccc", // Dynamic color for active/inactive
                                        color: "#fff",
                                        fontWeight: "bold",
                                        textTransform: "none", // Prevent uppercase text
                                        borderRadius: "8px", // Rounded corners
                                        boxShadow:
                                            "0px 4px 12px rgba(0, 0, 0, 0.1)", // Subtle shadow for depth
                                        transition:
                                            "background-color 0.3s ease, box-shadow 0.3s ease",
                                        "&:hover": {
                                            backgroundColor:
                                                isSubscriptionActive
                                                    ? "#1565c0"
                                                    : "#ccc", // Darker shade on hover
                                            boxShadow:
                                                "0px 6px 16px rgba(0, 0, 0, 0.2)", // Deeper shadow on hover
                                        },
                                        "&:disabled": {
                                            backgroundColor: "#ccc", // Disabled state styling
                                            color: "#999",
                                            cursor: "not-allowed",
                                        },
                                    }}
                                    disabled={
                                        buttonState.isGenerating ||
                                        buttonState.cooldown > 0 ||
                                        !coin.report.topHolders.length ||
                                        !isSubscriptionActive
                                    }
                                >
                                    {buttonState.isGenerating
                                        ? "Generating..."
                                        : "Generate New Report"}
                                </Button>
                            </span>
                        </Tooltip>

                        {buttonState.cooldown > 0 && (
                            <Typography variant="body2" color="textSecondary">
                                Next report available in {buttonState.cooldown}{" "}
                                seconds
                            </Typography>
                        )}
                    </Box>
                </Box>
            </Box>

            {/* Top 20 Holders */}
            {/* Legend */}

            <Box sx={{ display: "flex", gap: 2, mb: 2, width: "100%" }}>
                {/* Legend box with fixed width and left-aligned */}
                <Box
                    sx={{
                        width: "300px",
                        textAlign: "left",
                        padding: 2, // Add padding for a cleaner look
                        border: "1px solid #ccc", // Light border around the box
                        borderRadius: 2, // Rounded corners
                        backgroundColor: "#f9f9f9", // Light background color
                        boxShadow: 1, // Subtle shadow for depth
                        marginTop: "20px",
                    }}
                >
                    <Typography variant="h6" sx={{ mb: 1, fontWeight: "bold" }}>
                        Bundle alert:
                    </Typography>

                    {/* Display funded from totals */}
                    {Object.entries(fundedFromTotals).map(
                        ([address, totalPercent]) => {
                            const color = fundedFromColors[address];
                            const groupIndex = walletToGroup[address]; // Get group index, if exists
                            const bubbleColor =
                                getColorForAddressGroup(groupIndex); // Use group color if available

                            // If the address is part of a group, display only one entry for that group
                            if (groupIndex !== undefined) {
                                // Skip rendering if this group has already been rendered
                                if (renderedGroups.has(groupIndex)) {
                                    return null; // Skip this group, as it has already been rendered
                                }

                                // Mark this group as rendered
                                renderedGroups.add(groupIndex);

                                // Find the first address in the group
                                const firstGroupAddress = Object.keys(
                                    walletToGroup
                                ).find(
                                    (wallet) =>
                                        walletToGroup[wallet] === groupIndex
                                );
                                totalPercent = coin.report.topHolders
                                    .filter(
                                        (holder) =>
                                            walletToGroup[
                                                holder.walletAddress
                                            ] === groupIndex
                                    )
                                    .reduce(
                                        (sum, holder) =>
                                            sum + holder.percentHolding,
                                        0
                                    );

                                // Display the group (only border and percentage, no address)
                                return (
                                    <Box
                                        key={firstGroupAddress}
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            gap: 1,
                                            mb: 1, // Space between group entries
                                            borderRadius: 1, // Rounded corners for each entry
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                width: 25,
                                                height: 25,
                                                backgroundColor: bubbleColor,
                                            }}
                                        ></Box>
                                        <Typography>{`${(
                                            (totalPercent /
                                                coin.report.supply) *
                                            100
                                        ).toFixed(2)}%`}</Typography>{" "}
                                        {/* Only percentage */}
                                    </Box>
                                );
                            }

                            // Continue with individual addresses
                            return color ? (
                                <Box
                                    key={address}
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: 1,
                                        mb: 1, // Space between individual entries
                                    }}
                                >
                                    <Box
                                        sx={{
                                            width: 25,
                                            height: 25,
                                            backgroundColor: color,
                                        }}
                                    ></Box>
                                    <Typography>{`${(
                                        (totalPercent / coin.report.supply) *
                                        100
                                    ).toFixed(2)}%`}</Typography>
                                </Box>
                            ) : null;
                        }
                    )}

                    {/* Fresh wallets count */}
                    <Typography
                        variant="body2"
                        sx={{ mt: 2, fontWeight: "bold", color: "#333" }}
                    >
                        Fresh wallets (**):{" "}
                        <span style={{ color: "#1976d2" }}>
                            {freshWalletsCount}
                        </span>{" "}
                        {/* Display count of fresh wallets */}
                    </Typography>
                </Box>

                {/* Top 20 Holders section with full width and centered text */}
                <Box
                    sx={{
                        width: "100%",
                        textAlign: "center",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                    }}
                >
                    <Typography variant="h6" sx={{ mb: 2 }}>
                        Top 20 Holders
                    </Typography>
                </Box>
            </Box>

            <Box
                sx={{
                    width: "100%",
                    marginTop: 2,
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                {/* Table Header */}
                <Grid
                    container
                    sx={{
                        padding: 1,
                        backgroundColor: "#f0f0f0",
                        fontWeight: "bold",
                        borderBottom: "2px solid #ddd",
                    }}
                >
                    <Grid item xs={1}>
                        <Typography variant="body1" align="center">
                            #
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="body1">Address</Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography variant="body1" align="right">
                            Balance
                        </Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography variant="body1" align="right">
                            Age
                        </Typography>
                    </Grid>
                    <Grid item xs={1}>
                        <Typography variant="body1" align="right">
                            Holding %
                        </Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography variant="body1">Funded From</Typography>
                    </Grid>
                </Grid>

                {/* Table Rows with Accordion */}
                {coin.report.topHolders.map((holder, index) => {
                    const color = fundedFromColors[holder.walletFundedFrom];
                    const uniqueKey = `${holder.walletAddress}-${index}`;

                    const groupIndex = walletToGroup[holder.walletAddress];
                    const bubbleColor = getColorForAddressGroup(groupIndex);

                    return (
                        <Accordion
                            key={uniqueKey}
                            sx={{
                                backgroundColor:
                                    color || bubbleColor || "inherit", // Apply the color if available
                                marginBottom: 1, // Adds space between accordions
                            }}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls={`panel${index}-content`}
                                id={`panel${index}-header`}
                            >
                                <Grid container alignItems="center">
                                    <Grid item xs={1}>
                                        <Typography
                                            variant="body1"
                                            align="center"
                                        >
                                            {index + 1}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography variant="body1">
                                            <a
                                                href={`https://solscan.io/account/${holder.walletAddress}`}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                {holder.walletAddress.slice(
                                                    0,
                                                    4
                                                )}
                                                ...
                                                {holder.walletAddress.slice(-4)}
                                            </a>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Typography
                                            variant="body1"
                                            align="right"
                                        >
                                            {holder.solBalance.toFixed(2)} SOL
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Typography
                                            variant="body1"
                                            align="right"
                                            style={{
                                                fontWeight:
                                                    new Date(
                                                        holder.walletAge
                                                    ).toLocaleDateString() ===
                                                    new Date().toLocaleDateString()
                                                        ? "bold"
                                                        : "normal", // Apply bold conditionally
                                            }}
                                        >
                                            {new Date(
                                                holder.walletAge
                                            ).toLocaleDateString()}
                                            {new Date(
                                                holder.walletAge
                                            ).toLocaleDateString() ===
                                                new Date().toLocaleDateString() &&
                                                "**"}
                                            {/* Add asterisks if condition is met */}
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={1}>
                                        <Typography
                                            variant="body1"
                                            align="right"
                                        >
                                            {(
                                                (holder.percentHolding /
                                                    coin.report.supply) *
                                                100
                                            ).toFixed(2)}
                                            %
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Typography variant="body1">
                                            <a
                                                href={`https://solscan.io/account/${holder.walletFundedFrom}`}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                {getFundedFromSource(
                                                    holder.walletFundedFrom
                                                )}
                                            </a>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </AccordionSummary>

                            {/* Accordion Details (Transactions) */}
                            <AccordionDetails
                                sx={{
                                    backgroundColor: "#f1f1f1", // Light gray background
                                    padding: 2,
                                    borderTop: "1px solid #ddd", // Top border to separate from summary
                                }}
                            >
                                {holder.transactions &&
                                holder.transactions.length > 0 ? (
                                    <Box>
                                        {/* Transaction Header */}
                                        <Grid
                                            container
                                            sx={{
                                                fontWeight: "bold",
                                                marginBottom: 1,
                                            }}
                                        >
                                            <Grid item xs={3}>
                                                <Typography variant="body1">
                                                    Timestamp
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Typography variant="body1">
                                                    Timeblock
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Typography variant="body1">
                                                    Action
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Typography variant="body1">
                                                    Tokens Exchanged
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Typography variant="body1">
                                                    Transaction Hash
                                                </Typography>
                                            </Grid>
                                        </Grid>

                                        {/* Transactions */}
                                        {holder.transactions.map(
                                            (transaction, txnIndex) => {
                                                // Determine the background color and text color based on the action
                                                const isBuy =
                                                    transaction.action.toLowerCase() ===
                                                    "buy";
                                                const backgroundColor = isBuy
                                                    ? "darkgreen"
                                                    : "darkred";
                                                const color = "white";

                                                return (
                                                    <Grid
                                                        container
                                                        key={`txn-${txnIndex}`}
                                                        sx={{
                                                            borderBottom:
                                                                "1px solid #ddd",
                                                            paddingY: 1,
                                                            backgroundColor,
                                                            color,
                                                            "&:last-of-type": {
                                                                borderBottom:
                                                                    "none",
                                                            },
                                                        }}
                                                    >
                                                        <Grid item xs={3}>
                                                            <Typography variant="body2">
                                                                {new Date(
                                                                    transaction.timestamp
                                                                ).toLocaleString()}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={2}>
                                                            <Typography variant="body2">
                                                                {
                                                                    transaction.blockTime
                                                                }
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={2}>
                                                            <Typography variant="body2">
                                                                {
                                                                    transaction.action
                                                                }
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <Typography variant="body2">
                                                                {formatNumber(
                                                                    transaction.tokensExchanged
                                                                )}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={2}>
                                                            <a
                                                                href={`https://solscan.io/tx/${transaction.transactionHash}`}
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                                style={{
                                                                    color: color,
                                                                }} // Ensure link text is white
                                                            >
                                                                {transaction.transactionHash.slice(
                                                                    0,
                                                                    4
                                                                )}
                                                                ...
                                                                {transaction.transactionHash.slice(
                                                                    -4
                                                                )}
                                                            </a>
                                                        </Grid>
                                                    </Grid>
                                                );
                                            }
                                        )}
                                    </Box>
                                ) : (
                                    <Typography variant="body2">
                                        No transactions available for this
                                        holder.
                                    </Typography>
                                )}
                            </AccordionDetails>
                        </Accordion>
                    );
                })}
            </Box>
        </Box>
    );
};

export default Report;
