import React, { useState, useEffect, useCallback } from "react";
import {
    TextField,
    Button,
    Box,
    Typography,
    CircularProgress,
    Card,
    CardContent,
    CardMedia,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Alert
} from "@mui/material";
import axios from "axios";
import debounce from "lodash/debounce";

// Ensure the API key is correctly set

// Regex to validate Solana addresses (base58 encoded)
const SOLANA_ADDRESS_REGEX = /^[1-9A-HJ-NP-Za-km-z]{32,44}$/;

const AddCoinAddress = ({ token, refreshUserData, balance, message, setMessage, setOpenMessageModal, userAddresses }) => {
    const [coinAddress, setCoinAddress] = useState("");
    const [report] = useState(""); // Ensure report is a string
    const [coinName, setCoinName] = useState("");
    const [coinTicker, setCoinTicker] = useState("");
    const [coinImage, setCoinImage] = useState("");
    const [loading, setLoading] = useState(false);
    const [coinDetailsLoading, setCoinDetailsLoading] = useState(false);
    const [openReportModal, setOpenReportModal] = useState(false); // Manage report generation modal
    const [isValidAddress, setIsValidAddress] = useState(false);
    const url = process.env.REACT_APP_API_BASE_URL;


    // Debounced function to fetch coin details
    const fetchCoinDetails = useCallback(
        debounce(async (address) => {
            if (!SOLANA_ADDRESS_REGEX.test(address)) {
                setMessage("Invalid Solana token address format.");
                setCoinDetailsLoading(false);
                return;
            }

            setCoinDetailsLoading(true);
            try {
                const response = await axios.get(
                    `${url}/api/userCoins/details/${address}`,
                    { headers: { Authorization: `Bearer ${token}` } }
                );
                const coin = response.data;

                if (userAddresses.includes(address)) {
                    setMessage("Coin address already added", );
                }
                else if (coin && coin.name) {
                    setCoinName(coin.name || "Unknown");
                    setCoinTicker(coin.symbol || "N/A");
                    setCoinImage(coin.icon || "");
                    setMessage("");
                } else {
                    setCoinName("");
                    setCoinTicker("");
                    setCoinImage("");
                    setMessage("Coin details not found for the given address.");
                }
            } catch (error) {
                console.error("Error fetching coin details:", error);

                
                if (error.response) {
                    console.error("API Error Response:", error.response.data);
                }

                setMessage(
                    `Failed to retrieve coin details: ${
                        error.response
                            ? error.response.data.error
                            : error.message
                    }`
                );
            } finally {
                setCoinDetailsLoading(false);
            }
        }, 500),
        []
    );

    useEffect(() => {
        if (coinAddress) {
            // Validate coin address format
            if (SOLANA_ADDRESS_REGEX.test(coinAddress)) {
                setIsValidAddress(true);
                fetchCoinDetails(coinAddress);
            } else {
                setIsValidAddress(false);
                setCoinName("");
                setCoinTicker("");
                setCoinImage("");
                setMessage("Invalid Solana token address format.");
            }
        } else {
            setIsValidAddress(false);
            setCoinName("");
            setCoinTicker("");
            setCoinImage("");
            setMessage("");
        }
    }, [coinAddress, fetchCoinDetails]);

    const handleAddCoinAddress = async () => {
        setOpenReportModal(true);
        if (!token) {
            setMessage("No token provided. Please log in.");
            setOpenMessageModal(true);
            return;
        }

        setLoading(true);

        try {
            const response = await axios.post(
                `${url}/api/user/add-coin-address`,
                {
                    coinAddress,
                    report,
                    coinName,
                    coinTicker,
                    coinImage,
                },
                { headers: { Authorization: `Bearer ${token}` } }
            );

            setMessage(
                response.data.message || "Coin address added successfully!"
            );
            refreshUserData();

            // Open success modal
            setOpenMessageModal(true);
        } catch (error) {
            setMessage(
                `Error: ${
                    error.response ? error.response.data.error : error.message
                }`
            );
            setOpenMessageModal(true);
        } finally {
            setLoading(false);
        }
    };

    const handleCloseReportModal = () => {
        setOpenReportModal(false);
    };

    return (
        <Box>
            <Typography variant="h6" gutterBottom>
                Add Coin Address
            </Typography>
            <Typography variant="p" gutterBottom>
                Metadata takes ~15 mintutes to generate. If metadata is not
                available, the coin will be displayed as it's address
            </Typography>
            <TextField
                label="Coin Address"
                variant="outlined"
                fullWidth
                value={coinAddress}
                onChange={(e) => setCoinAddress(e.target.value)}
                margin="normal"
            />
            {coinDetailsLoading ? (
                <CircularProgress />
            ) : (
                <>
                    {coinName && (
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                padding: 2,
                                border: "1px solid #ddd",
                                borderRadius: 1,
                                maxWidth: 345,
                                marginTop: 2,
                            }}
                        >
                            {coinImage && (
                                <Box
                                    component="img"
                                    src={coinImage}
                                    alt={`${coinName} image`}
                                    sx={{
                                        width: 100,
                                        height: 100,
                                        objectFit: "cover",
                                        borderRadius: 1,
                                    }}
                                />
                            )}
                            <Box sx={{ marginLeft: 2 }}>
                                <Typography variant="h6" component="div">
                                    {coinName}
                                </Typography>
                                <Typography
                                    variant="body2"
                                    color="text.secondary"
                                >
                                    ${coinTicker}
                                </Typography>
                            </Box>
                        </Box>
                    )}
                </>
            )}
            <Box mt={2}>
                <Button
                    variant="contained"
                    onClick={handleAddCoinAddress}
                    disabled={
                        loading ||
                        !isValidAddress ||
                        userAddresses.includes(coinAddress)
                    }
                    sx={{
                        color: "white",
                        bgcolor: "#0A5C36",
                        "&:hover": {
                            bgcolor: "#1D6A4B", // Lighter green for hover
                        },
                    }}
                >
                    {loading ? "Adding..." : "Add Coin Address"}
                </Button>

                {/* Display message with improved styling */}
                {message && (
                    <Box mt={2}>
                        <Alert
                            severity={
                                message.includes("Invalid") ||
                                message.includes("Failed") ||
                                message.includes("already added")
                                    ? "error"
                                    : "success"
                            }
                        >
                            {message}
                        </Alert>
                    </Box>
                )}
            </Box>

            {/* Report Generation Modal */}
            <Dialog
                open={openReportModal}
                onClose={handleCloseReportModal}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle>Report Generation</DialogTitle>
                <DialogContent>
                    <Typography variant="body1">
                        Thanks! Your report is being generated - please allow
                        several minutes. You will receive an alert when your
                        report is finished. Please refresh. If your transaction
                        fails, you will not be charged.
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleCloseReportModal}
                        sx={{
                            color: "white",
                            bgcolor: "#0A5C36",
                            "&:hover": {
                                bgcolor: "#1D6A4B", // Lighter green for hover
                            },
                        }}
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default AddCoinAddress;
