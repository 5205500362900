import React, { useEffect, useState } from "react";
import axios from "axios";
import { Box } from "@mui/material";
import LoggedOutHome from "./LoggedOutHome";
import LoggedInHome from "./LoggedInHome";

const Home = () => {
    const [userData, setUserData] = useState(null);
    const [balance, setBalance] = useState(null);
    const [copySuccess, setCopySuccess] = useState("");
    const [loading, setLoading] = useState(true);
    const [tokenValid, setTokenValid] = useState(true);
    const token = localStorage.getItem("token");
    const url = process.env.REACT_APP_API_BASE_URL;

    useEffect(() => {
        const fetchData = async () => {
            if (token) {
                try {
                    const userResponse = await axios.get(`${url}/api/user/me`, {
                        headers: { Authorization: `Bearer ${token}` },
                    });
                    setUserData(userResponse.data);

                    // Fetch user balance
                    const balanceResponse = await axios.get(
                        `${url}/api/wallet/balance`,
                        {
                            headers: { Authorization: `Bearer ${token}` },
                        }
                    );
                    setBalance(balanceResponse.data.balance);
                } catch (error) {
                    if (error.response?.status === 401) {
                        setTokenValid(false);
                        localStorage.removeItem("token"); // Clear token on expiration
                    }
                    console.error("Error fetching data:", error);
                }
            }
            setLoading(false); // Set loading to false after data fetching is complete
        };

        fetchData();
    }, [token, url]);

    const handleCopy = () => {
        if (userData && userData.solanaWallet) {
            navigator.clipboard
                .writeText(userData.solanaWallet)
                .then(() => setCopySuccess("Address copied to clipboard!"))
                .catch(() => setCopySuccess("Failed to copy address."));
        }
    };

    const refreshUserData = async () => {
        try {
            const userResponse = await axios.get(`${url}/api/user/me`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            setUserData(userResponse.data);

            const balanceResponse = await axios.get(
                `${url}/api/wallet/balance`,
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            );
            setBalance(balanceResponse.data.balance);
        } catch (error) {
            console.error("Error refreshing user data:", error);
        }
    };

    if (loading) {
        return <div>Loading...</div>; // Optionally show a loading spinner or message
    }

    if (!tokenValid) {
        return <LoggedOutHome />; // Show the logged out state if token is not valid
    }

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                minHeight: "100vh",
                width: "100%",
                backgroundColor: "#ffffff",
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    flex: 1,
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                {token ? (
                    <LoggedInHome
                        userData={userData}
                        balance={balance}
                        handleCopy={handleCopy}
                        copySuccess={copySuccess}
                        refreshUserData={refreshUserData}
                        token={token}
                    />
                ) : (
                    <LoggedOutHome />
                )}
            </Box>
        </Box>
    );
};

export default Home;
