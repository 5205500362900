import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import axios from "axios";

const PrivateRoute = ({ children }) => {
    const [authorized, setAuthorized] = useState(null); // Use `null` to indicate loading state
    const [loading, setLoading] = useState(true); // Track loading state
    const token = localStorage.getItem("token");
    const url = process.env.REACT_APP_API_BASE_URL;

    useEffect(() => {
        const checkTokenValidity = async () => {
            try {
                if (token) {
                    await axios.get(`${url}/api/user/me`, {
                        headers: { Authorization: `Bearer ${token}` },
                    });
                    setAuthorized(true);
                } else {
                    setAuthorized(false);
                }
            } catch (error) {
                if (error.response?.status === 401) {
                    setAuthorized(false);
                    localStorage.removeItem("token"); // Clear token on expiration
                } else {
                    // Handle other errors if needed
                    console.error("Token validation error:", error);
                }
            } finally {
                setLoading(false); // Set loading to false after the check is done
            }
        };

        checkTokenValidity();
    }, [token, url]);

    if (loading) {
        return <div>Loading...</div>; // Optionally show a loading spinner or message
    }

    if (!authorized) {
        return <Navigate to="/login" />;
    }

    return children;
};

export default PrivateRoute;
