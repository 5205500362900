import React, { useState, useEffect } from "react";
import {
    Typography,
    IconButton,
    Snackbar,
    Box,
    Button,
    Modal,
    Checkbox,
    FormControlLabel,
    Avatar,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Tooltip,
    Drawer, // Added for responsive sidebar
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
    Close as CloseIcon,
    CopyAll as CopyAllIcon,
    Menu as MenuIcon, // Added for menu button on mobile
} from "@mui/icons-material";
import AddCoinAddress from "./AddCoinAddress";
import PurchaseSubscription from "./PurchaseSubscription";
import Report from "./Report"; // Import the new Report component
import { useTheme } from "@mui/material/styles"; // Added for theming
import useMediaQuery from "@mui/material/useMediaQuery"; // Added for responsive detection

const LoggedInHome = ({ userData, balance, refreshUserData, token }) => {
    // Existing state variables
    const [copySuccessOpen, setCopySuccessOpen] = useState(false);
    const [selectedAddress, setSelectedAddress] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [privateKey, setPrivateKey] = useState("");
    const [privateKeyModalOpen, setPrivateKeyModalOpen] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [acknowledged, setAcknowledged] = useState(false);
    const [userCoins, setUserCoins] = useState([]);
    const [user, setUser] = useState(null);
    const [openMessageModal, setOpenMessageModal] = useState(false); // Manage success message modal
    const [message, setMessage] = useState("");
    const url = process.env.REACT_APP_API_BASE_URL;
    const [userAddresses, setUserAddresses] = useState([]);
    const [subscriptionModalOpen, setSubscriptionModalOpen] = useState(false); // Modal is now open by default
    const [timeLeft, setTimeLeft] = useState("");

    // New state for mobile drawer
    const [drawerOpen, setDrawerOpen] = useState(false);

    const navigate = useNavigate();

    const isSubscriptionActive =
        new Date(userData.subscription.endDate) > new Date();

    // Theme and media query for responsiveness
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // Adjust breakpoint as needed

    useEffect(() => {
        const storedPrivateKey = localStorage.getItem("privateKey");
        if (storedPrivateKey) {
            setPrivateKey(storedPrivateKey);
            setPrivateKeyModalOpen(true);
            localStorage.removeItem("privateKey"); // Remove private key after showing
        }
    }, []);

    useEffect(() => {
        // Function to calculate time difference
        const calculateTimeLeft = () => {
            const endDate = new Date(userData?.subscription?.endDate);
            const now = new Date();
            const difference = endDate - now;

            if (difference > 0) {
                const days = Math.floor(difference / (1000 * 60 * 60 * 24));
                const hours = Math.floor(
                    (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
                );
                const minutes = Math.floor(
                    (difference % (1000 * 60 * 60)) / (1000 * 60)
                );
                const seconds = Math.floor((difference % (1000 * 60)) / 1000);

                if (days > 0) {
                    setTimeLeft(`${days}d ${hours}h ${minutes}m`);
                } else {
                    setTimeLeft(`${hours}h ${minutes}m ${seconds}s`);
                }
            } else {
                setTimeLeft("Subscription expired");
            }
        };

        // Calculate initially
        calculateTimeLeft();

        // Set up an interval to update the countdown every second
        const timer = setInterval(calculateTimeLeft, 1000);

        // Cleanup interval on component unmount
        return () => clearInterval(timer);
    }, [userData?.subscription?.endDate]);

    useEffect(() => {
        const fetchUserCoins = async () => {
            try {
                const response = await fetch(`${url}/api/userCoins/coins`, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                });

                if (!response.ok) {
                    throw new Error("Failed to fetch user coins");
                }

                const data = await response.json();
                setUserCoins(data);
                let addresses = [];
                data.forEach((item) => {
                    addresses.push(item.coinAddress);
                });

                // Update the state with the new array of coin addresses
                setUserAddresses(addresses);
            } catch (err) {
                console.error("Error fetching user coins:", err);
            }
        };

        fetchUserCoins();
    }, [token, url]);

    const handleCloseModal = () => {
        setOpenMessageModal(false);
    };

    const handleCopyToClipboard = async () => {
        if (privateKey) {
            try {
                await navigator.clipboard.writeText(privateKey);
                setSnackbarOpen(true);
            } catch (err) {
                console.error("Failed to copy private key: ", err);
            }
        } else {
            console.warn("Private key is empty.");
        }
    };

    const handleAcknowledgmentChange = (event) => {
        setAcknowledged(event.target.checked);
    };

    const handleCopyClick = () => {
        if (userData && userData.solanaWallet) {
            navigator.clipboard
                .writeText(userData.solanaWallet)
                .then(() => setCopySuccessOpen(true))
                .catch(() => setCopySuccessOpen(false));
        }
    };

    const handleLogout = () => {
        localStorage.removeItem("token");
        setUser(null);
        navigate("/login");
    };

    const handleAddressClick = (address) => {
        setSelectedAddress(address);
    };

    // Function to toggle drawer
    const toggleDrawer = (open) => (event) => {
        if (
            event.type === "keydown" &&
            (event.key === "Tab" || event.key === "Shift")
        ) {
            return;
        }
        setDrawerOpen(open);
    };

    // Sidebar content for Drawer and permanent sidebar
    const sidebarContent = (
        <>
            {/* Add Token Button */}
            <Tooltip
                title={
                    !isSubscriptionActive
                        ? "Please activate subscription to add token"
                        : ""
                }
                arrow
            >
                <span>
                    <Button
                        variant="contained"
                        sx={{
                            mb: 2,
                            width: "100%",
                            fontSize: "1.2rem",
                            fontWeight: "bold",
                            bgcolor: "#0A5C36",
                            "&:hover": {
                                bgcolor: "#1D6A4B", // Lighter green for hover
                            },
                        }}
                        onClick={() => setModalOpen(true)}
                        disabled={!isSubscriptionActive}
                    >
                        Add Token
                    </Button>
                </span>
            </Tooltip>

            {/* Coin Address List */}
            {userCoins
                .slice(0)
                .reverse()
                .map((coin, index) => (
                    <Box
                        key={index}
                        sx={{
                            p: 1,
                            mb: 1,
                            cursor: "pointer",
                            backgroundColor:
                                selectedAddress === coin
                                    ? "#b0bec5"
                                    : "#e0e0e0",
                            borderRadius: 1,
                            display: "flex",
                            alignItems: "center",
                            "&:hover": {
                                backgroundColor:
                                    selectedAddress === coin
                                        ? "#90a4ae"
                                        : "#c0c0c0",
                            },
                        }}
                        onClick={() => handleAddressClick(coin)}
                    >
                        <Avatar
                            src={coin.coinImage}
                            sx={{
                                width: 24,
                                height: 24,
                                mr: 1,
                                borderRadius: 0,
                            }}
                        />
                        <Box sx={{ flex: 1 }}>
                            <Typography variant="body2" noWrap>
                                {coin.coinName && coin.coinTicker ? (
                                    <>
                                        {coin.coinName}{" "}
                                        <strong>${coin.coinTicker}</strong>
                                    </>
                                ) : (
                                    `${coin.coinAddress.slice(
                                        0,
                                        4
                                    )}...${coin.coinAddress.slice(-4)}`
                                )}
                            </Typography>
                        </Box>
                    </Box>
                ))}
        </>
    );

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column", // Changed to column for better stacking on mobile
                minHeight: "100vh",
                backgroundColor: "#ffffff",
                width: "100%",
            }}
        >
            {/* Drawer for mobile remains the same */}
            <Drawer
                anchor="left"
                open={drawerOpen}
                onClose={toggleDrawer(false)}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
            >
                <Box
                    sx={{
                        width: 250,
                        p: 2,
                        mt: isMobile ? 8 : 0, // Adjust top margin if AppBar was present
                    }}
                    role="presentation"
                    onClick={toggleDrawer(false)}
                    onKeyDown={toggleDrawer(false)}
                >
                    {sidebarContent}
                </Box>
            </Drawer>

            {/* Permanent sidebar for desktop */}
            {!isMobile && (
                <Box
                    sx={{
                        position: "fixed",
                        top: 0,
                        left: 0,
                        bottom: 0,
                        width: "300px",
                        backgroundColor: "#f4f4f4",
                        overflowY: "auto",
                        borderRight: "1px solid #ddd",
                        p: 2,
                        mt: 8, // Add margin-top to create space for the fixed banner
                    }}
                >
                    {sidebarContent}
                </Box>
            )}

            {/* Main Content */}
            <Box
                sx={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    p: 2,
                    marginLeft: !isMobile ? "300px" : "0", // Add margin if sidebar is present
                    mt: isMobile ? 16 : 0, // Increase top margin for mobile to accommodate two fixed rows
                }}
            >
                {/* Address Display */}
                <Box
                    sx={{
                        position: "fixed",
                        top: 0,
                        left: 0,
                        right: 0,
                        display: "flex",
                        flexDirection: isMobile ? "column" : "row", // Column for mobile, row for desktop
                        alignItems: "center",
                        justifyContent: isMobile
                            ? "space-between"
                            : "space-between",
                        backgroundColor: "#ffffff",
                        borderBottom: "1px solid #ddd", // Add a bottom border to distinguish the banner
                        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)", // Subtle shadow for elevation
                        padding: "10px 20px", // Add padding for a balanced look
                        zIndex: 10, // Ensure it stays on top
                        width: "100%",
                        boxSizing: "border-box", // Ensure padding doesn't affect width
                    }}
                >
                    {isMobile ? (
                        <>
                            {/* Top Row: Drawer Icon and Logout */}
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    width: "100%",
                                    mb: 1,
                                }}
                            >
                                <IconButton
                                    onClick={toggleDrawer(true)}
                                    aria-label="open drawer"
                                >
                                    <MenuIcon />
                                </IconButton>
                                <Button
                                    variant="contained"
                                    onClick={handleLogout}
                                    sx={{
                                        bgcolor: "#0A5C36",
                                        "&:hover": {
                                            bgcolor: "#1D6A4B",
                                        },
                                    }}
                                >
                                    Logout
                                </Button>
                            </Box>

                            {/* Bottom Row: Address and Balance/Subscription */}
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    width: "100%",
                                }}
                            >
                                {/* Left: Address */}
                                <Box
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    {/* Conditionally render the title based on screen size */}
                                    <Typography
                                        variant="body1"
                                        sx={{
                                            fontWeight: "bold",
                                            display: {
                                                xs: "none",
                                                sm: "block",
                                            }, // Hide title on mobile
                                        }}
                                    >
                                        Your Address:
                                    </Typography>
                                    <Typography
                                        variant="body1"
                                        sx={{ ml: 1, wordBreak: "break-all" }}
                                    >
                                        {userData?.solanaWallet &&
                                            `${userData.solanaWallet.slice(
                                                0,
                                                5
                                            )}...${userData.solanaWallet.slice(
                                                -4
                                            )}`}
                                    </Typography>
                                    <IconButton
                                        onClick={handleCopyClick}
                                        sx={{ ml: 1 }}
                                    >
                                        <CopyAllIcon
                                            sx={{ color: "#0A5C36" }}
                                        />
                                    </IconButton>
                                </Box>

                                {/* Right: Balance and Subscription */}
                                <Box
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    <Typography variant="body1" sx={{ mr: 1 }}>
                                        Balance:{" "}
                                        {balance !== null
                                            ? balance.toFixed(2)
                                            : "Loading..."}{" "}
                                        SOL
                                    </Typography>
                                    {!isSubscriptionActive ? (
                                        <Button
                                            variant="contained"
                                            onClick={() =>
                                                setSubscriptionModalOpen(true)
                                            }
                                            sx={{
                                                bgcolor: "#0A5C36",
                                                "&:hover": {
                                                    bgcolor: "#1D6A4B",
                                                },
                                            }}
                                        >
                                            Purchase Subscription
                                        </Button>
                                    ) : (
                                        <Typography variant="body1">
                                            Time Left: {timeLeft || "N/A"}
                                        </Typography>
                                    )}
                                </Box>
                            </Box>
                        </>
                    ) : (
                        // Desktop Layout
                        <>
                            {/* Left Section */}
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                                <Typography
                                    variant="body1"
                                    sx={{ ml: 2, fontWeight: "bold" }}
                                >
                                    Your Address:
                                </Typography>
                                <Typography
                                    variant="body1"
                                    sx={{ ml: 1, wordBreak: "break-all" }}
                                >
                                    {userData?.solanaWallet &&
                                        `${userData.solanaWallet.slice(
                                            0,
                                            5
                                        )}...${userData.solanaWallet.slice(
                                            -4
                                        )}`}
                                </Typography>
                                <IconButton
                                    onClick={handleCopyClick}
                                    sx={{ ml: 2 }}
                                >
                                    <CopyAllIcon sx={{ color: "#0A5C36" }} />
                                </IconButton>
                            </Box>

                            {/* Right Section */}
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                                <Typography variant="body1" sx={{ mr: 2 }}>
                                    Balance:{" "}
                                    {balance !== null
                                        ? balance.toFixed(2)
                                        : "Loading..."}{" "}
                                    SOL
                                </Typography>
                                {!isSubscriptionActive ? (
                                    <Button
                                        variant="contained"
                                        onClick={() =>
                                            setSubscriptionModalOpen(true)
                                        }
                                        sx={{
                                            mr: 2,
                                            bgcolor: "#0A5C36",
                                            "&:hover": {
                                                bgcolor: "#1D6A4B",
                                            },
                                        }}
                                    >
                                        Purchase Subscription
                                    </Button>
                                ) : (
                                    <Typography variant="body1" sx={{ mr: 2 }}>
                                        Subscription Time Left:{" "}
                                        {timeLeft || "N/A"}
                                    </Typography>
                                )}
                                <Button
                                    variant="contained"
                                    onClick={handleLogout}
                                    sx={{
                                        mr: 2,
                                        bgcolor: "#0A5C36",
                                        "&:hover": {
                                            bgcolor: "#1D6A4B",
                                        },
                                    }}
                                >
                                    Logout
                                </Button>
                            </Box>
                        </>
                    )}
                </Box>

                {/* Snackbar for Copy Success */}
                <Snackbar
                    open={copySuccessOpen}
                    autoHideDuration={2000}
                    onClose={() => setCopySuccessOpen(false)}
                    message="Address copied to clipboard!"
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                />

                {/* Coin Address Detail */}
                <Box
                    sx={{
                        flex: 1,
                        display: "flex",
                        alignItems: "start",
                        justifyContent: "center",
                        p: 2,
                        width: "100%",
                        mt: 2, // Add top margin to avoid overlapping with fixed top bar
                    }}
                >
                    {selectedAddress && (
                        <Report
                            coin={selectedAddress}
                            token={token}
                            balance={balance}
                            isSubscriptionActive={isSubscriptionActive}
                        />
                    )}
                </Box>
            </Box>

            {/* Add Coin Address Modal */}
            <Modal
                open={modalOpen}
                onClose={() => setModalOpen(false)}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: isMobile ? "90%" : 400, // Responsive width
                        bgcolor: "background.paper",
                        border: "2px solid #000",
                        boxShadow: 24,
                        p: 4,
                    }}
                >
                    <AddCoinAddress
                        token={token}
                        refreshUserData={refreshUserData}
                        balance={balance}
                        message={message}
                        setMessage={setMessage}
                        setOpenMessageModal={setOpenMessageModal}
                        userAddresses={userAddresses}
                    />
                </Box>
            </Modal>
            <PurchaseSubscription
                subscriptionModalOpen={subscriptionModalOpen}
                setSubscriptionModalOpen={setSubscriptionModalOpen}
                balance={balance}
            />
            {/* Success Message Modal */}
            <Dialog
                open={openMessageModal}
                onClose={handleCloseModal}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle>Message</DialogTitle>
                <DialogContent>
                    <Typography variant="body1">{message}</Typography>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleCloseModal}
                        sx={{
                            color: "white",
                            bgcolor: "#0A5C36",
                            "&:hover": {
                                bgcolor: "#1D6A4B", // Lighter green for hover
                            },
                        }}
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Private Key Modal */}
            <Modal
                open={privateKeyModalOpen}
                onClose={() => setPrivateKeyModalOpen(false)}
            >
                <Box
                    sx={{
                        ...modalStyle,
                        width: isMobile ? "90%" : 400, // Responsive width
                    }}
                >
                    <Typography
                        variant="h6"
                        color="error"
                        sx={{ mb: 2, fontWeight: "bold" }}
                    >
                        WARNING: This is the only time you will ever be able to
                        see your private key. Save it and keep it somewhere
                        secure.
                    </Typography>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={acknowledged}
                                onChange={handleAcknowledgmentChange}
                            />
                        }
                        label="I acknowledge I will never be able to see my private key again."
                        sx={{ mb: 2 }}
                    />

                    {acknowledged && (
                        <>
                            <Typography variant="h6" gutterBottom>
                                Your Private Key
                            </Typography>
                            <Typography
                                variant="body1"
                                sx={{
                                    wordBreak: "break-all",
                                    mb: 2,
                                    backgroundColor: "#f5f5f5",
                                    padding: 1,
                                    borderRadius: 1,
                                }}
                            >
                                {privateKey}
                            </Typography>
                            <Button
                                onClick={handleCopyToClipboard}
                                variant="contained"
                                sx={{
                                    mr: 1,
                                    color: "white",
                                    bgcolor: "#0A5C36",
                                    "&:hover": {
                                        bgcolor: "#1D6A4B", // Lighter green for hover
                                    },
                                }}
                            >
                                Copy to Clipboard
                            </Button>
                            <Button
                                onClick={() => setPrivateKeyModalOpen(false)}
                                variant="contained"
                                color="primary"
                            >
                                Close
                            </Button>
                        </>
                    )}
                </Box>
            </Modal>

            <Snackbar
                open={snackbarOpen}
                autoHideDuration={2000}
                onClose={() => setSnackbarOpen(false)}
                message="Private key copied to clipboard!"
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
            />
        </Box>
    );
};

const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};

export default LoggedInHome;
