import React, { useState, useEffect } from "react";
import axios from "axios";
import {
    TextField,
    Button,
    Container,
    Typography,
    Box,
    Link,
    Checkbox,
    FormControlLabel,
} from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom"; // Import useLocation
import TokenBanner from "./TokenBanner";

const Register = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const [tosAccepted, setTosAccepted] = useState(false);
    const [referral, setReferral] = useState(null); // State to hold referral code
    const navigate = useNavigate();
    const location = useLocation(); // Access location to get query params
    const baseUrl = process.env.REACT_APP_API_BASE_URL;

    // Extract the referral code from the query parameters
    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const referralCode = searchParams.get("referral");
        if (referralCode) {
            setReferral(referralCode); // Set referral if it exists
        }
    }, [location]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!tosAccepted) {
            setError("You must accept the Terms of Service to register.");
            return;
        }

        try {
            const response = await axios.post(
                `${baseUrl}/api/auth/register`,
                {
                    email,
                    password,
                    tosAccepted,
                    referral, // Send referral in the request
                },
                { headers: { "Content-Type": "application/json" } }
            );
            const { token, walletAddress, privateKey } = response.data;

            // Store token, user details, and private key in local storage
            localStorage.setItem("token", token);
            localStorage.setItem("email", email);
            localStorage.setItem("walletAddress", walletAddress);
            localStorage.setItem("privateKey", privateKey); // Store private key

            // Redirect to wallet page
            navigate("/");
        } catch (error) {
            setError("Error registering. Please try again with a new username");
        }
    };

    return (
        <Container
            maxWidth="sm"
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "100vh",
                backgroundColor: "#ffffff",
            }}
        >
            <TokenBanner />

            <Box
                sx={{
                    width: "100%",
                    maxWidth: 400,
                    p: 3,
                    borderRadius: 2,
                    boxShadow: 3,
                    backgroundColor: "#ffffff",
                    textAlign: "center",
                }}
            >
                <Typography variant="h4" gutterBottom sx={{ color: "#0A5C36" }}>
                    Register
                </Typography>
                <form onSubmit={handleSubmit}>
                    <TextField
                        label="Username"
                        type="text"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                    <TextField
                        label="Password"
                        type="password"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={tosAccepted}
                                onChange={(e) =>
                                    setTosAccepted(e.target.checked)
                                }
                                color="primary"
                            />
                        }
                        label={
                            <Typography>
                                I agree to the{" "}
                                <Link
                                    href="/terms-of-service"
                                    target="_blank"
                                    rel="noopener"
                                >
                                    Terms of Service
                                </Link>
                            </Typography>
                        }
                    />
                    {error && <Typography color="error">{error}</Typography>}
                    <Button
                        type="submit"
                        variant="contained"
                        fullWidth
                        sx={{
                            mt: 2,
                            bgcolor: "#0A5C36",
                            "&:hover": {
                                bgcolor: "#1D6A4B", // Lighter green for hover
                            },
                        }}
                    >
                        Register
                    </Button>
                </form>
                <Typography sx={{ mt: 2 }}>
                    Already have an account?{" "}
                    <Link href="/login" color="#0A5C36">
                        Login
                    </Link>
                </Typography>
            </Box>
        </Container>
    );
};

export default Register;
