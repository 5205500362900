import React from "react";
import { Box, Typography, Container } from "@mui/material";

const LiabilityStatement = () => {
    return (
        <Container maxWidth="md">
            <Box
                sx={{
                    padding: "20px",
                    backgroundColor: "#f9f9f9",
                    borderRadius: "8px",
                    boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
                }}
            >
                <Typography variant="h4" gutterBottom>
                    Liability Statement
                </Typography>

                <Typography variant="body1" paragraph>
                    The content on this website is provided for general
                    information purposes only. We do not guarantee the accuracy,
                    completeness, or reliability of any information presented.
                    Any reliance on this information is at your own risk.
                </Typography>

                <Typography variant="body1" paragraph>
                    Under no circumstances shall Token Summary, its directors,
                    employees, or affiliates be liable for any direct, indirect,
                    incidental, special, or consequential damages resulting from
                    the use of this website, even if advised of the possibility
                    of such damages.
                </Typography>

                <Typography variant="body1" paragraph>
                    The information on this website is not intended to
                    substitute for professional legal, financial, or medical
                    advice. Users should seek the advice of qualified
                    professionals regarding any concerns or questions.
                </Typography>

                <Typography variant="body1" paragraph>
                    Our website may contain links to third-party websites. We
                    have no control over the content or practices of these sites
                    and accept no responsibility for any damages or losses
                    incurred by using them.
                </Typography>

                <Typography variant="body1" paragraph>
                    By accessing or using our website, users agree that they are
                    solely responsible for any consequences arising from their
                    use of the website or its content.
                </Typography>

                <Typography variant="body1" paragraph>
                    We are not responsible for any loss of Solana or other
                    cryptocurrency funds due to user errors, wallet issues,
                    transaction errors, or any other issues related to the use
                    of third-party wallets or the Solana network. Users are
                    solely responsible for safeguarding their private keys and
                    ensuring the accuracy of transactions.
                </Typography>

                <Typography variant="body1" paragraph>
                    All content, including text, images, and code, is the
                    intellectual property of Token Summary unless otherwise
                    noted. Unauthorized use of any content may result in legal
                    action.
                </Typography>

                <Typography variant="body1" paragraph>
                    We reserve the right to update or change this disclaimer at
                    any time. Users are encouraged to check this page regularly
                    for any changes.
                </Typography>

                <Typography variant="body1" paragraph>
                    This agreement is governed by and construed in accordance
                    with the laws of the United States of America, and any
                    disputes relating to these terms and conditions will be
                    subject to the jurisdiction of the courts of United States
                    of America.
                </Typography>
            </Box>
        </Container>
    );
};

export default LiabilityStatement;
