import React, { useEffect, useState } from "react";
import axios from "axios";
import Home from "./Home";
import { useNavigate } from "react-router-dom";
import LoggedInHome from "./LoggedInHome";

const MainComponent = () => {
    const [user, setUser] = useState(null);
    const navigate = useNavigate();
    const url = process.env.REACT_APP_API_BASE_URL;

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const response = await axios.get(
                    `${url}/api/user/me`,
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem(
                                "token"
                            )}`,
                        },
                    }
                );
                setUser(response.data);
            } catch (error) {
                console.error("Error fetching user data:", error);
                navigate("/login"); // Redirect to login if user data cannot be fetched
            }
        };

        fetchUserData();
    }, [navigate]);

    return (
        <div>
            {user ? <LoggedInHome user={user} /> : <p>Loading...</p>}
        </div>
    );
};

export default MainComponent;
