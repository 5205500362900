import React from "react";
import { Box, Typography, Button } from "@mui/material";
import { Link } from "react-router-dom"; // Import Link from react-router-dom

const TokenBanner = () => {
    return (
        <Box
            sx={{
                position: "fixed",
                top: 0,
                left: 0,
                right: 0,
                backgroundColor: "#f5f5f5",
                padding: "10px 20px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between", // Space between items
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                zIndex: 1000,
            }}
        >
            <Link
                to="/"
                style={{
                    textDecoration: "none",
                    display: "flex",
                    alignItems: "center",
                }}
            >
                <Box
                    component="img"
                    src={require("../assets/tokensummary.png")}
                    alt="Token Summary"
                    sx={{
                        height: "40px",
                        marginRight: "20px",
                    }}
                />
                <Typography
                    sx={{
                        fontSize: "24px",
                        fontWeight: 500,
                        color: "#333",
                    }}
                >
                    Token Summary
                </Typography>
            </Link>
            <Box>
                <Button
                    component={Link}
                    to="/login"
                    variant="contained"
                    sx={{
                        backgroundColor: "#0A5C36", // Green color
                        color: "#fff", // White text color
                        marginRight: "10px",
                        "&:hover": {
                            backgroundColor: "#0A4A2F", // Darker green on hover
                        },
                    }}
                >
                    Login
                </Button>
                <Button
                    component={Link}
                    to="/register"
                    variant="contained"
                    sx={{
                        backgroundColor: "#0A5C36", // Green color
                        color: "#fff", // White text color
                        "&:hover": {
                            backgroundColor: "#0A4A2F", // Darker green on hover
                        },
                    }}
                >
                    Register
                </Button>
            </Box>
        </Box>
    );
};

export default TokenBanner;
